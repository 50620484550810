import { useEffect, useRef, useState } from "react";
import readXlsxFile from "read-excel-file";

import OpenAI from "openai";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Input from "@mui/material/Input";
import Delete from "@mui/icons-material/Delete";
import LinearProgress from "@mui/material/LinearProgress";

import { HttpCustomApi } from "../../interface/custom-api";
import { HttpMainApi } from "../../interface/main-api";
import { userState, systemIndex, tennantInfo } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import PopXAnswers from "./PopXAnswers";

interface propsType {
  userState: userState;
  tennantInfo: tennantInfo;
}

const mainApi = new HttpMainApi();
const vectoCoreApi = new HttpCustomApi(process.env.REACT_APP_VECTOR_CORE);

const openai = new OpenAI({
  apiKey: "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",
  dangerouslyAllowBrowser: true,
});

const fileGroup: any = [
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/csv",
  "application/excel",
];

let _maxCount: number = 10;

const MngXSearch = (props: propsType) => {
  const toastRef: any = useRef();
  const popAnswerRef: any = useRef();
  const attachInputRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [indexes, setIndexes] = useState<any>([]);
  const [selectedIndexName, setSelectedIndexName] = useState("-1");
  const [docs, setDocs] = useState<any>([]);
  const [lastKey, setLastKey] = useState("");
  const [selectedModel, setSelectedModel] = useState("naver");

  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState<any>([]);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    getIndexes();
    // getDocs();
    return () => {
      setIsLoading(false);
      setIndexes([]);
      setSelectedIndexName("-1");
      setSelectedModel("naver");
      setDocs([]);
      setQuestion("");
      setAnswers([]);
      setProgress(0);
    };
  }, []);

  const getIndexes = async () => {
    setIsLoading(true);
    const res = await vectoCoreApi.auth_request("api", { command: "list_index" }, props.tennantInfo.tennant_key);
    if (res.code === "200") {
      let temp_lit = [];
      for (const index of res.response.index_list) {
        if (!systemIndex.includes(index) && index.substr(0, 6) === "naverx") temp_lit.push(index);
      }
      setIndexes(temp_lit.sort());
      setSelectedIndexName(temp_lit.sort()[0]);
    } else {
      console.error("getIndexes : ", res.response.error_msg);
    }
    setIsLoading(false);
  };

  const getEmbeddingText = async (_text: string) => {
    if (selectedModel === "openai") {
      const result = await openai.embeddings.create({
        input: _text,
        model: "text-embedding-ada-002",
      });

      return result.data[0].embedding;
    } else if (selectedModel === "naver") {
      return await mainApi.naver_embedding({ text: _text });
    }
  };

  const getDocs = async () => {
    setProgress(0);
    const param: any = {
      command: "list_extream_data",
      index_name: selectedIndexName,
      last_key: "",
    };

    setIsLoading(true);
    const res = await vectoCoreApi.auth_request("api", param, props.tennantInfo.tennant_key);
    console.log("getDocs : ", res);
    if (res.code === "200") {
      setDocs(
        res.response.data_list.sort(function (a: any, b: any) {
          if (a.sk < b.sk) return -1;
          else if (a.sk > b.sk) return 1;
          else return 0;
        })
      );
      setLastKey(lastKey);
    }

    setIsLoading(false);
  };

  const getDocsMore = async () => {
    const param: any = {
      command: "list_extream_data",
      index_name: selectedIndexName,
      last_key: lastKey,
    };

    setIsLoading(true);
    const res = await vectoCoreApi.auth_request("api", param, props.tennantInfo.tennant_key);
    console.log("getDocsMore : ", res);
    if (res.code === "200") {
      setDocs(
        res.response.data_list.sort(function (a: any, b: any) {
          if (a.sk < b.sk) return -1;
          else if (a.sk > b.sk) return 1;
          else return 0;
        })
      );
      setLastKey(lastKey);
    }

    setIsLoading(false);
  };

  const deleteDoc = async (_docId: string) => {
    if (selectedIndexName === "-1" || selectedIndexName === undefined) {
      toastRef.current?.toast("Please select index", "error", 3000, { vertical: "top", horizontal: "center" });
      return;
    }
    if (!window.confirm("Delete document?")) return;

    // ITNE Vector delete
    const param: any = {
      command: "delete_doc",
      index_name: selectedIndexName,
      doc_id: _docId,
    };
    setIsLoading(true);
    const res = await vectoCoreApi.auth_request("api", param, props.tennantInfo.tennant_key);
    console.log("res : ", res);
    if (res.code !== "200") {
      toastRef.current?.toast("Error : " + res.rsponse.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
      return;
    }

    // extream search db delete
    const extParam: any = {
      command: "delete_extream_data",
      index_name: selectedIndexName,
      doc_id: _docId,
    };

    const extRes = await vectoCoreApi.auth_request("api", extParam, props.tennantInfo.tennant_key);
    console.log("extRes : ", extRes);
    if (extRes.code === "200") {
      toastRef.current?.toast("Success delete document", "success", 3000, { vertical: "top", horizontal: "center" });
      getDocs();
    } else {
      toastRef.current?.toast("Error : " + extRes.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const handelSelectedIndexChange = (event: SelectChangeEvent) => {
    setSelectedIndexName(event.target.value as string);
  };

  const handelSelectedModelChange = (event: SelectChangeEvent) => {
    setSelectedModel(event.target.value as string);
  };

  useEffect(() => {
    if (selectedIndexName !== "-1") {
      getDocs();
    } else {
      setDocs([]);
    }
  }, [selectedIndexName]);

  function DocsRow(props: { docInfo: ReturnType<any> }) {
    const { docInfo } = props;
    console.log(docInfo);
    const [open, setOpen] = useState(false);
    const jsonKeys: any = Object.keys(docInfo.data).sort();
    return (
      <>
        <TableRow key={docInfo.sk} sx={open ? {} : { "& > *": { borderBottom: "unset" } }}>
          <TableCell width={20}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {docInfo.sk}
          </TableCell>
          <TableCell align="right">
            <IconButton
              onClick={() => {
                deleteDoc(docInfo.sk);
              }}
            >
              <Delete className="data-table-img-button color-red" />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2, whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                <Paper elevation={3} sx={{ borderRadius: "1rem", mt: 1, p: 3, backgroundColor: "rgb(202, 202, 202)" }}>
                  {jsonKeys.map((keyName: string, index: number) => (
                    <Paper key={"data_detail_" + index} elevation={0} sx={{ borderRadius: "0.5rem", pt: 2, pb: 2, pl: 3, pr: 3, mt: 2 }}>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ width: "150px", p: 1 }}>{keyName}</Box>
                        <Paper
                          elevation={0}
                          sx={{
                            borderRadius: "0.5rem",
                            backgroundColor: "rgb(202, 202, 202)",
                            p: 1,
                            width: "100%",
                            color: "rgb(92, 92, 92)",
                          }}
                        >
                          {JSON.stringify(docInfo.data[keyName])}
                        </Paper>
                      </Box>
                    </Paper>
                  ))}
                </Paper>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const fncQuestion = async () => {
    if (selectedIndexName === "-1" || selectedIndexName === undefined) {
      toastRef.current?.toast("Please select index", "error", 3000, { vertical: "top", horizontal: "center" });
      return;
    }

    setIsLoading(true);
    // extream search
    const extParam: any = {
      command: "extream_search",
      index_name: selectedIndexName,
      question: question, // 사용자 질문
      max_count: _maxCount, // 검색결과 최대 수량
    };

    console.log("extParam : ", extParam);

    const extRes = await vectoCoreApi.auth_request("api", extParam, props.tennantInfo.tennant_key);
    console.log(extRes);
    if (extRes.code === "200") {
      setAnswers(extRes.response.result);
      popAnswerRef.current.open(true);
    } else {
      toastRef.current?.toast("Error fncQuestion : " + extRes.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
    }

    setIsLoading(false);
  };

  const handleAttachInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptableFormats = fileGroup;
    if (e.target.files !== null) {
      const files: FileList = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!acceptableFormats.includes(file.type)) {
          alert("지원하지 않는 포멧입니다. Excel파일을 올려주세요.");
          return;
        }
      }

      setIsLoading(true);
      for await (const file of files) {
        const rows = await readXlsxFile(file);

        const fileName: string = file.name;
        let index: number = 0;
        const colList: any = rows[0];
        for await (const row of rows) {
          if (index > 0) {
            const id: string = `${fileName}-${String(index).padStart(5, "0")}`;
            const meta: string = `{ 'source':${fileName}, 'page':'${index}'}`;
            const text: string = row[0].toString();
            // embedding
            const embeddingResult = await getEmbeddingText(text);
            console.log("- embeddingResult : ", embeddingResult);

            if (embeddingResult === null) {
              toastRef.current?.toast("Error embedding", "error", 3000, { vertical: "top", horizontal: "center" });
              attachInputRef.current.value = "";
              setIsLoading(false);
              getDocs();
              return;
            }

            // itne vectore db insert
            const param: any = {
              command: "create_doc",
              index_name: selectedIndexName,
              doc_body: {
                id: id,
                text: text,
                metadata: meta,
                embedding: embeddingResult,
              },
            };
            const vectorResult = await vectoCoreApi.auth_request("api", param, props.tennantInfo.tennant_key);
            console.log("vectorResult [" + index + "] : ", vectorResult);
            if (vectorResult.code !== "200") {
              toastRef.current?.toast("Error create vector in H.I.Vector :" + vectorResult.response.error_msg, "error", 3000, {
                vertical: "top",
                horizontal: "center",
              });
              attachInputRef.current.value = "";
              setIsLoading(false);
              getDocs();
              return;
            }

            // extream search db insert
            const insertData: any = {
              pk: selectedIndexName,
              sk: id,
            };
            const detailData: any = {};
            for (let i = 0; i < colList.length; i++) {
              detailData[colList[i]] = row[i];
            }

            insertData["data"] = detailData;

            const insertParam: any = {
              command: "put_extream_data",
              data: insertData,
            };

            const extreamResult = await vectoCoreApi.auth_request("api", insertParam, props.tennantInfo.tennant_key);
            console.log("extreamResult [" + index + "] : ", extreamResult);
            if (extreamResult.code !== "200") {
              toastRef.current?.toast("Error put data in extream_db", "error", 3000, { vertical: "top", horizontal: "center" });
              attachInputRef.current.value = "";
              setIsLoading(false);
              getDocs();
              return;
            }
          }

          index = index + 1;
          const progress = (index / rows.length) * 100;
          setProgress(progress);
        }
      }
      attachInputRef.current.value = "";
      setIsLoading(false);
      getDocs();
    }
  };

  return (
    <>
      <Box sx={{ p: 0 }}>
        <Stack direction={"column"} spacing={0} sx={{ height: "100vh", overflow: "auto" }}>
          <Box className="my-account-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              Application eXtream Search
            </Typography>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Box className="apikey-content-header">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography gutterBottom className="my-account-content-sub-title">
                  Manage eXtream Search Documents in Index here
                </Typography>
              </Box>
            </Box>
            <Stack direction="column" spacing={2}>
              <FormControl fullWidth>
                <InputLabel id="lableCollection" size="small">
                  Collection
                </InputLabel>
                <Select
                  labelId="lableCollection"
                  id="selectedCollection"
                  value={selectedIndexName}
                  label="Collection"
                  onChange={handelSelectedIndexChange}
                  size="small"
                >
                  <MenuItem key={"-1"} value="-1">
                    선택
                  </MenuItem>
                  {indexes.map((name: any, index: number) => (
                    <MenuItem key={index + "" + name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="lableModel" size="small">
                  Model
                </InputLabel>
                <Select
                  labelId="lableModel"
                  id="selectedModel"
                  value={selectedModel}
                  label="Model"
                  onChange={handelSelectedModelChange}
                  size="small"
                >
                  <MenuItem value={"naver"}>Naver</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  console.log("1234");
                  attachInputRef.current?.click();
                }}
              >
                Upload Excel
              </Button>
              {progress > 0 && <LinearProgress variant="determinate" value={progress} />}
              <TableContainer component={Paper}>
                <Table className="data-table">
                  <TableBody>
                    {docs.map((docInfo: any, index: number) => (
                      <DocsRow key={docInfo.sk + "" + index} docInfo={docInfo} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {lastKey !== "" && (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    getDocsMore();
                  }}
                >
                  More
                </Button>
              )}
            </Stack>
          </Box>
          {selectedIndexName !== "-1" && (
            <>
              <Box sx={{ p: "12px" }}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    id="txtQuestion"
                    value={question}
                    placeholder="Test Question"
                    variant="outlined"
                    fullWidth
                    required
                    size="small"
                    onChange={(e) => {
                      setQuestion(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") fncQuestion();
                    }}
                  />
                  <Button
                    variant="outlined"
                    sx={{ minWidth: "200px" }}
                    onClick={() => {
                      fncQuestion();
                    }}
                  >
                    Question
                  </Button>
                </Stack>
              </Box>
            </>
          )}
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <PopXAnswers ref={popAnswerRef} question={question} answers={answers} />
      <Input
        key="attachment"
        color="primary"
        type="file"
        inputProps={{ multiple: true }}
        inputRef={attachInputRef}
        onChange={handleAttachInput}
        sx={{ display: "none" }}
      />
    </>
  );
};

export default MngXSearch;
