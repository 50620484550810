/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
const awsmobile = {
  aws_project_region: "ap-northeast-2",

  Auth: {
    region: "ap-northeast-2",
    userPoolId: "ap-northeast-2_Ji82LdJYD", // Please change this value.
    userPoolWebClientId: "3csf66g4ai6j7sakklbgnrpq3o", // Please change this value.
    identityPoolId: "ap-northeast-2:a07e5b5d-d64c-4501-8e3e-08cc56459315",
  },
  aws_cloud_logic_custom: [
    {
      name: "MainApi",
      endpoint: "https://west6dbi2e.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
    {
      name: "UnAuth",
      endpoint: "https://west6dbi2e.execute-api.ap-northeast-2.amazonaws.com/Dev/",
      region: "ap-northeast-2",
    },
  ],
};

export default awsmobile;
