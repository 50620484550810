import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Input from "@mui/material/Input";
import Delete from "@mui/icons-material/Delete";

interface propsType {
  question: string;
  answers: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "700px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 3,
  pt: 1,
};

const PopXAnswers = (props: propsType, ref: any) => {
  const indexNameRef: any = useRef();

  const [openModal, setOpenModal] = useState(false);

  function DocsRow(props: { docInfo: ReturnType<any> }) {
    const { docInfo } = props;
    const [open, setOpen] = useState(false);
    const jsonKeys: any = Object.keys(docInfo).sort();

    return (
      <>
        <TableRow key={docInfo.sk} sx={open ? {} : { "& > *": { borderBottom: "unset" } }}>
          <TableCell width={20}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {docInfo.sk} [{docInfo.score}]
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2, whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                <Paper elevation={3} sx={{ borderRadius: "1rem", mt: 1, p: 3, backgroundColor: "rgb(202, 202, 202)" }}>
                  {jsonKeys.map((keyName: string, index: number) => (
                    <Paper key={"data_detail_" + index} elevation={0} sx={{ borderRadius: "0.5rem", pt: 2, pb: 2, pl: 3, pr: 3, mt: 2 }}>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box sx={{ width: "150px", p: 1 }}>{keyName}</Box>
                        <Paper
                          elevation={0}
                          sx={{
                            borderRadius: "0.5rem",
                            backgroundColor: "rgb(202, 202, 202)",
                            p: 1,
                            width: "100%",
                            color: "rgb(92, 92, 92)",
                          }}
                        >
                          {JSON.stringify(docInfo[keyName])}
                        </Paper>
                      </Box>
                    </Paper>
                  ))}
                </Paper>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  useEffect(() => {
    return () => {
      setOpenModal(false);
    };
  }, []);

  const open = useCallback((open: boolean) => {
    setOpenModal(open);
  }, []);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={0}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <CloseIcon className="color-red" />
              </IconButton>
            </Box>
            <Stack direction="column" spacing={2}>
              <TextField id="txtQuestion" value={props.question} variant="outlined" fullWidth />
              <TableContainer component={Paper} sx={{ height: "598px", overflowY: "auto" }}>
                <Table className="data-table">
                  <TableBody>
                    {props.answers.map((docInfo: any, index: number) => (
                      <DocsRow key={docInfo.id + "" + index} docInfo={docInfo} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default forwardRef(PopXAnswers);
