import { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { HttpCustomApi } from "../../interface/custom-api";
import { userState, tennantInfo } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import CreateIndex from "./CreateIndex";
import { Delete } from "@mui/icons-material";

interface propsType {
  userState: userState;
  tennantInfo: tennantInfo;
}

const vectoCoreApi = new HttpCustomApi(process.env.REACT_APP_VECTOR_CORE);

const MngIndex = (props: propsType) => {
  const toastRef: any = useRef();
  const createIndexRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [indexes, setIndexes] = useState<any>([]);

  const getIndexes = async () => {
    setIsLoading(true);
    const res = await vectoCoreApi.auth_request("api", { command: "list_index" }, props.tennantInfo.tennant_key);
    if (res.code === "200") {
      setIndexes(res.response.index_list.sort());
    } else {
      console.error("getIndexes : ", res.response.error_msg);
    }
    setIsLoading(false);
  };

  const createIndex = async () => {
    createIndexRef.current.open(true);
  };

  const deleteIndex = async (_indexName: string) => {
    if (!window.confirm("Delete Index? This action is irreversible.")) return;

    const param: any = {
      command: "delete_index",
      index_name: _indexName,
    };
    setIsLoading(true);
    const res = await vectoCoreApi.auth_request("api", param, props.tennantInfo.tennant_key);
    if (res.code === "200") {
      toastRef.current?.toast("Success delete index", "success", 3000, { vertical: "top", horizontal: "center" });
      getIndexes();
    } else {
      toastRef.current?.toast("Error : " + res.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getIndexes();
    return () => {
      setIsLoading(false);
      setIndexes([]);
    };
  }, []);

  return (
    <>
      <Box sx={{ p: 0 }}>
        <Stack direction={"column"} spacing={0} sx={{ height: "100vh", overflow: "auto" }}>
          <Box className="my-account-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              Application Indexes
            </Typography>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Box className="apikey-content-header">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography gutterBottom className="my-account-content-sub-title">
                  Manage Indexes in Application here
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button variant="contained" onClick={createIndex}>
                  Create Index
                </Button>
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table className="data-table">
                <TableBody>
                  {indexes.map((indexName: any) => (
                    <TableRow key={indexName} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {indexName}
                      </TableCell>
                      <TableCell align="right">
                        <CopyToClipboard
                          text={indexName}
                          onCopy={() => {
                            toastRef.current?.toast("Copy to clipboard is complete.", "success", 3000, {
                              vertical: "top",
                              horizontal: "center",
                            });
                          }}
                        >
                          <IconButton>
                            <ContentCopyIcon className="data-table-img-button" />
                          </IconButton>
                        </CopyToClipboard>
                        <IconButton
                          onClick={() => {
                            deleteIndex(indexName);
                          }}
                        >
                          <Delete className="data-table-img-button color-red" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <CreateIndex ref={createIndexRef} userState={props.userState} getIndexes={getIndexes} tennantInfo={props.tennantInfo} />
    </>
  );
};

export default MngIndex;
