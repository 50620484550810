import { useEffect, useRef, useState } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { HttpMainApi } from "../../interface/main-api";
import { HttpCustomApi } from "../../interface/custom-api";
import { HttpUnAuthApi } from "../../interface/unauth-api";
import { tennantInfo, emptyTennantInfo } from "../../interface/MainInterface";
import { CommonUtils } from "../../utils/common_utils";
import Toast from "../../utils/Toast";

interface propsType {
  info: any;
  fncGetTennantList: any;
}
const mainApi = new HttpMainApi();
const onboardingApi = new HttpCustomApi(process.env.REACT_APP_ONBOARDING_URL);
const unAuthApi = new HttpUnAuthApi();

const BtnRequest = (props: propsType) => {
  const toastRef: any = useRef();
  const cUtils = new CommonUtils();

  const [tennantInfo, setTennantInfo] = useState<tennantInfo>(emptyTennantInfo);
  const [resource, setResource] = useState<any>(null);

  const fncRequestOnboarding = async () => {
    if (!window.confirm("Create Tenant?")) return;

    const onbdParam: any = {
      tenant_key: tennantInfo.tennant_key,
    };

    console.log("onbdParam : ", onbdParam);
    // await onboardingApi.common_request("createtemplate", onbdParam);
    await fncUpdateStatus("REQUEST");
    await fncCreateApiKey();
  };

  const fncGetTenantInfo = async () => {
    const param: any = {
      tennant_id: props.info.sk,
    };
    const res = await mainApi.get_tennant_info(param);
    if (res.code === "200") {
      setTennantInfo(res.response.tennant_info);
      fncGetTenantResource(res.response.tennant_info);
    } else {
      console.error("get_tennant_info : ", res.response.error_msg);
    }
  };

  const fncGetTenantResource = async (tenant_info: any) => {
    const param: any = {
      tenant_key: tenant_info.tennant_key,
    };
    const res = await mainApi.get_tenant_resource(param);
    console.log("res : ", res);
    if (res.code === "200") {
      setResource(res.response.resource_info);
    }
  };

  const fncUpdateStatus = async (status: string) => {
    const param: any = {
      pk: tennantInfo.pk,
      sk: tennantInfo.sk,
      status: status,
    };
    await mainApi.update_tenant_status(param);
    await props.fncGetTennantList();
    await fncGetTenantInfo();
  };

  const fncDropTenant = async () => {
    if (!window.confirm("Drop Tenant?")) return;

    const onbdParam: any = {
      tenant_key: tennantInfo.tennant_key,
    };

    await onboardingApi.common_request("deletetemplate", onbdParam);
    await fncUpdateStatus("DROPPING");
    await fncDeleteApiKey();
    const param: any = {
      email: tennantInfo.sk,
    };
    await unAuthApi.delete_user(param);
  };

  const fncCreateApiKey = async () => {
    const param: any = {
      tenant_key: tennantInfo.tennant_key,
    };
    await mainApi.create_api_key(param);
  };

  const fncDeleteApiKey = async () => {
    const param: any = {
      tenant_key: tennantInfo.tennant_key,
    };
    await mainApi.delete_api_key(param);
  };

  useEffect(() => {
    fncGetTenantInfo();
    return () => {
      setTennantInfo(emptyTennantInfo);
      setResource(null);
    };
  }, []);

  return (
    <>
      {tennantInfo.tennant_status === "CREATE" && (
        <Button
          variant="contained"
          onClick={() => {
            fncRequestOnboarding();
          }}
        >
          Request OnBoarding
        </Button>
      )}
      {tennantInfo.tennant_status === "REQUEST" && cUtils.isEmptyObj(resource) && (
        <Button
          variant="outlined"
          endIcon={<RefreshIcon />}
          onClick={() => {
            fncGetTenantResource(tennantInfo);
          }}
        >
          OnBording...
        </Button>
      )}
      {tennantInfo.tennant_status === "REQUEST" && !cUtils.isEmptyObj(resource) && (
        <Button
          variant="contained"
          onClick={() => {
            fncUpdateStatus("COMPLETE");
          }}
        >
          Comfirm
        </Button>
      )}
      {tennantInfo.tennant_status === "COMPLETE" && resource !== null && (
        <>
          <Stack direction={"row"} spacing={2}>
            <Button variant="contained" onClick={fncDropTenant} startIcon={<DeleteForeverIcon />}>
              Drop
            </Button>
          </Stack>
        </>
      )}
      {tennantInfo.tennant_status === "DROPPING" && resource !== null && resource.check_use && (
        <Button
          variant="outlined"
          endIcon={<RefreshIcon />}
          onClick={() => {
            fncGetTenantResource(tennantInfo);
          }}
        >
          Dropping...
        </Button>
      )}
      {tennantInfo.tennant_status === "DROPPING" && resource !== null && !resource.check_use && (
        <Button
          variant="contained"
          onClick={() => {
            fncUpdateStatus("DROPPED");
          }}
        >
          Comfirm
        </Button>
      )}
      <Toast ref={toastRef} />
    </>
  );
};

export default BtnRequest;
