import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import FaceIcon from "@mui/icons-material/Face";

import { userState } from "../interface/MainInterface";
import Logo from "./Logo";

interface propsType {
  userState: userState;
  backGround: string;
}

const Header = (props: propsType) => {
  let navigate = useNavigate();
  const [anchorElAdmin, setAnchorElAdmin] = useState<null | HTMLElement>(null);
  const handleOpenAdmin = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAdmin(event.currentTarget);
  };
  const handleCloseAdmin = () => {
    setAnchorElAdmin(null);
  };

  const [anchorElMyMenu, setAnchorElMyMenu] = useState<null | HTMLElement>(null);
  const handleOpenMyMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMyMenu(event.currentTarget);
  };
  const handleCloseMyMenu = () => {
    setAnchorElMyMenu(null);
  };

  const fncSignUp = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  const fncSignIn = useCallback(() => {
    navigate("/signin");
  }, [navigate]);

  const fncSignOut = useCallback(async () => {
    if (!window.confirm("로그아웃 하시겠습니까?")) return;
    await Auth.signOut();
    window.location.href = "/";
  }, []);

  const goMenu = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  useEffect(() => {
    return () => {
      setAnchorElAdmin(null);
      setAnchorElMyMenu(null);
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: "0", zIndex: "700", backgroundColor: "transparent" }}>
      {/* 상단 고정메뉴 Component */}
      <AppBar
        position="fixed"
        sx={
          props.backGround === "dark"
            ? { backgroundColor: "#f8f9fa", boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)" }
            : { backgroundColor: "transparent", boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)" }
        }
      >
        {/* 상단 툴바 */}
        <Toolbar
          sx={
            props.backGround === "dark"
              ? {
                  minHeight: "56px !important",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "snow",
                  backgroundColor: "rgb(18, 17, 66);",
                }
              : {
                  minHeight: "56px !important",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "rgb(18, 17, 66);",
                  backgroundColor: "transparent",
                }
          }
        >
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Logo />
          </Box>
          <Box>
            {props.userState.isSuperAdmin && (
              <>
                <Button id="admin-button" onClick={handleOpenAdmin}>
                  Admin
                </Button>
                <Menu id="admin-menu" anchorEl={anchorElAdmin} open={Boolean(anchorElAdmin)} onClose={handleCloseAdmin}>
                  <MenuItem
                    onClick={() => {
                      handleCloseAdmin();
                      goMenu("/mngTennants");
                    }}
                  >
                    Tennant List
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseAdmin();
                      goMenu("/costTotal");
                    }}
                  >
                    Cost Explore
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
          <Box>
            {props.userState.isAuth ? (
              <>
                <IconButton onClick={handleOpenMyMenu}>
                  <FaceIcon sx={{ color: "snow" }} />
                </IconButton>
                <Menu id="my-menu" anchorEl={anchorElMyMenu} open={Boolean(anchorElMyMenu)} onClose={handleCloseMyMenu}>
                  <MenuItem
                    onClick={() => {
                      handleCloseMyMenu();
                      goMenu("/myAccount");
                    }}
                  >
                    My Account
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseMyMenu();
                      fncSignOut();
                    }}
                  >
                    Sign Out
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Stack spacing={2} direction="row">
                <Button variant="text" onClick={fncSignIn}>
                  Log In
                </Button>
                <Button variant="contained" onClick={fncSignUp}>
                  Sign Up
                </Button>
              </Stack>
            )}
          </Box>
        </Toolbar>
        {/* 상단 툴바 */}
      </AppBar>
    </Box>
  );
};

export default Header;
