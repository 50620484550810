import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import KeyIcon from "@mui/icons-material/Key";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import SourceIcon from "@mui/icons-material/Source";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";

import {
  userState,
  tennantInfo,
  emptyTennantInfo,
  xSearchAuth,
} from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";
import LoadingCircle from "../../utils/LoadingCircle";
import LogoLeft from "../../mainContainer/LogoLeft";

import ApiKeys from "./ApiKeys";
import Settings from "./Settings";
import MngIndex from "./MngIndex";
import MngDocs from "./MngDocs";
import MngXSearch from "./MngXSearch";

interface propsType {
  userState: userState;
}

const cssLeftButton: any = {
  m: "0 0 2px",
  p: "8px",
  borderRadius: "4px",
  "&.Mui-selected": {
    backgroundColor: "rgb(40, 47, 92);",
    "&:hover": { backgroundColor: "rgb(40, 47, 92);" },
  },
  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1);" },
};

const mainApi = new HttpMainApi();

const MyAccount = (props: propsType) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(2);
  const [tennantInfo, setTennantInfo] = useState<tennantInfo>(emptyTennantInfo);
  const [resource, setResource] = useState<any>(null);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const get_tennant_info = async () => {
    const param: any = {
      tennant_id: props.userState.id,
    };
    const res = await mainApi.get_tennant_info(param);
    if (res.code === "200") {
      setTennantInfo(res.response.tennant_info);
      get_tenant_resource(res.response.tennant_info);
    } else {
      console.error("get_tennant_info : ", res.response.error_msg);
    }
  };

  const get_tenant_resource = async (tenant_info: any) => {
    const param: any = {
      tenant_key: tenant_info.tennant_key,
    };
    const res = await mainApi.get_tenant_resource(param);
    if (res.code === "200") {
      setResource(res.response.resource_info);
    }
  };

  useEffect(() => {
    get_tennant_info();
    return () => {
      setIsLoading(false);
      setSelectedIndex(-1);
      setResource(null);
    };
  }, []);

  return (
    <>
      <Box sx={{ p: 0 }}>
        <Stack direction="row" spacing={0}>
          <Box className="left-menu-root">
            <LogoLeft />
            <Box className="left-user-name">{tennantInfo.name}</Box>
            <nav aria-label="main mailbox folders">
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                    sx={cssLeftButton}
                  >
                    <ListItemIcon className="left-icon-btn">
                      <ListAltIcon className="left-icons" />
                    </ListItemIcon>
                    <ListItemText primary="Indexes" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                    sx={cssLeftButton}
                  >
                    <ListItemIcon className="left-icon-btn">
                      <FolderCopyIcon className="left-icons" />
                    </ListItemIcon>
                    <ListItemText primary="Documents" />
                  </ListItemButton>
                </ListItem>
                {/* {xSearchAuth.includes(props.userState.id) && ( */}
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3)}
                    sx={cssLeftButton}
                  >
                    <ListItemIcon className="left-icon-btn">
                      <FolderSpecialIcon className="left-icons" />
                    </ListItemIcon>
                    <ListItemText primary="X-Search" />
                  </ListItemButton>
                </ListItem>
                {/* )} */}
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                    sx={cssLeftButton}
                  >
                    <ListItemIcon className="left-icon-btn">
                      <KeyIcon className="left-icons" />
                    </ListItemIcon>
                    <ListItemText primary="API Keys" />
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
            <Box sx={{ flexGrow: "1" }}></Box>
            <Box className="left-footer-menu">
              <nav aria-label="main mailbox folders">
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        window.open("/manual", "hivec_manual");
                      }}
                      sx={cssLeftButton}
                    >
                      <ListItemIcon className="left-icon-btn">
                        <SourceIcon className="left-icons" />
                      </ListItemIcon>
                      <ListItemText primary="Guides" />
                    </ListItemButton>
                  </ListItem>
                  <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.25)" }} />
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={selectedIndex === 99}
                      onClick={(event) => handleListItemClick(event, 99)}
                      sx={cssLeftButton}
                    >
                      <ListItemIcon className="left-icon-btn">
                        <SettingsIcon className="left-icons" />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </nav>
              <Button
                variant="outlined"
                onClick={async () => {
                  if (!window.confirm("로그아웃 하시겠습니까?")) return;
                  await Auth.signOut();
                  window.location.href = "/";
                }}
                className="btn-left-sign-out"
              >
                Sign Out
              </Button>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            {selectedIndex === 0 && (
              <MngIndex userState={props.userState} tennantInfo={tennantInfo} />
            )}
            {selectedIndex === 1 && (
              <MngDocs userState={props.userState} tennantInfo={tennantInfo} />
            )}
            {selectedIndex === 2 && (
              <ApiKeys
                userState={props.userState}
                tennantInfo={tennantInfo}
                resource={resource}
                get_tennant_info={get_tennant_info}
              />
            )}
            {
              selectedIndex === 3 && (
                // xSearchAuth.includes(props.userState.id) && (
                <MngXSearch
                  userState={props.userState}
                  tennantInfo={tennantInfo}
                />
              )
              // )
            }
            {selectedIndex === 99 && <Settings userState={props.userState} />}
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MyAccount;
