import { CodeBlock, dracula } from "react-code-blocks";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface propsType {}

const request_module: string = `import axios from "axios";

const requestPost => async (requestBody: any) => {
  let header: any = { "Content-Type": "application/json" };
  const response = await axios.post(<USER API URL>, requestBody, header);
  return response.data.body;
}
`;

const create_index_code: string = `const createIndex => async () => {
  const param: any = {
      command: "create",
      type: "index",
      name: "test-index-01",
      body: {
          settings: {
              knn: true,
              "knn.algo_param.ef_search": 100,
          },
          mappings: {
              properties: {
                  id: {
                      type: "text",
                  },
                  text: {
                      type: "text",
                  },
                  metadata: {
                      type: "text",
                  },
                  embedding: {
                      type: "knn_vector",
                      dimension: 1024, // naver : 1024, openai : 1536
                      method: {
                        name: "hnsw",
                        space_type: "l2",
                        engine: "nmslib",
                        parameters: {
                            ef_construction: 128,
                            m: 24,
                        },
                      }
                  },
              },
          },
      },
  };

  const response = await requestPost(param)
}`;

const create_document_code: string = `const createDoc => async () => {
    const param: any = {
        command: "create",
        type: "doc",
        name: "test-index-01",
        body: {
            "id": "test-01",
            "text": "hello world",
            "metadata": "{ 'source':'test-01', 'page':'01'}" // free format
            "embedding": [ <vecrots> ]
        }
    };

    const response = await requestPost(param)
}`;

const get_document_code: string = `const getDoc => async () => {
    const param: any = {
        command: "get",
        type: "doc",
        name: "test-index-01",
        body: {
            "answer_num" : 2, // 가져올 Ranking 숫자
            "vector" : 질문 텍스트를 벡터로 변환한 데이터,
            "vector_column_name" : "embedding",
            "vector_num" : 2 // knn 숫자
        }
    };

    const response = await requestPost(param)
}`;

const response_code: string = `{
  "statusCode": 200,
  "body": {
      "status_code": 200,
      "error_msg": "",
      "rtn_msg": "",
      "max_score": 0.031011762,
      "result": [
          {
              "id": "test-01",
              "text": <원본 TEXT>,
              "metadata": "{ 'source':영양json.txt, 'page':'0'}",
              "embedding": [ <vectors>... ],
              "score": 0.031011762
          }, ...
      ]
  }
}`;

const InfoUsage = (props: propsType) => {
  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0} sx={{ height: "100vh", overflow: "auto" }}>
          <Box className="info-main-header-root">
            <Typography variant="h5" gutterBottom className="info-main-header-title">
              Usage Guide
            </Typography>
          </Box>
          <Divider />
          <Box className="info-main-content-root">
            <div id="top" className="width-100">
              <Box className="info-main-content-header">
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography gutterBottom className="info-main-content-title">
                    H.I.Vector를 이용하여 embedding 데이터를 저장하고 쿼리하는 간단한 방법을 알 수 있습니다.
                  </Typography>
                </Box>
              </Box>
            </div>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      API setting
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  H.I.Vector는 기본적으로 API로 동작하기 때문에 예제에서는 Typescript를 기반으로 HTTP POST를 호출하는 모듈을 생성하겠습니다.
                </Typography>
                <Box>
                  <CodeBlock text={request_module} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
                </Box>
              </Box>
            </Box>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      Create Index
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  도큐먼트를 저장하기 위해서 일반 데이터베이스의 테이블과 유사한 개념인 인덱스를 생성합니다.
                </Typography>
                <Box>
                  <CodeBlock
                    text={create_index_code}
                    language={"typescript"}
                    showLineNumbers={false}
                    startingLineNumber={1}
                    theme={dracula}
                  />
                </Box>
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  "test-index-01"이라는 인덱스를 생성하며 column은 id, text, metadata, embedding으로 구성된 도큐먼트를 저장할 것이라고
                  명시합니다. 또한 embedding은 "knn_vector" type으로 vector values를 저장하고 차원은 1024차원(여기에선 naver embedding
                  사용), 거리 계산은 l2 방법을 사용합니다.
                </Typography>
              </Box>
            </Box>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      Create Document
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  생성된 인덱스에 데이터를 추가합니다.
                </Typography>
                <Box>
                  <CodeBlock
                    text={create_document_code}
                    language={"typescript"}
                    showLineNumbers={false}
                    startingLineNumber={1}
                    theme={dracula}
                  />
                </Box>
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  test-index-01 인덱스에 test-01 ID를 가진 도큐먼트를 추가합니다. embedding에는 naver tool을 이용하여 변환된 vector를
                  입력합니다.
                </Typography>
              </Box>
            </Box>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      Get Document
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  질문하고자 하는 텍스트와 유사한 vector들을 유사도 순서로 쿼리합니다.
                </Typography>
                <Box>
                  <CodeBlock
                    text={get_document_code}
                    language={"typescript"}
                    showLineNumbers={false}
                    startingLineNumber={1}
                    theme={dracula}
                  />
                </Box>
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  쿼리하고자하는 텍스트를 벡터로 변환후 벡터 데이터베이스의 embedding 컬럼에서 쿼리하면 유사도의 순서대로 list로 결과를
                  리턴합니다.
                </Typography>
                <Box sx={{ mt: 4 }}></Box>
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  응답
                </Typography>
                <Box>
                  <CodeBlock text={response_code} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default InfoUsage;
