import { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsExports from "./config/aws-exports";
import "./App.css";

import * as AuthMain from "./auth/AuthMain";
import { userState } from "./interface/MainInterface";
// import Header from "./mainContainer/Header";
import MainConteiner from "./mainContainer/MainConteiner";
import { HttpMainApi } from "./interface/main-api";
Amplify.configure(awsExports);

let userParam: userState = {
  id: "",
  name: "",
  company: "",
  isAuth: false,
  isSuperAdmin: false,
  apiUrl: "",
};

const superAdmin = ["bluegury@hyundai-ite.com", "sijin@hyundai-ite.com"];

const LayoutDivider = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "55px !important",
  padding: theme.spacing(0, 0),
  justifyContent: "flex-end",
}));

const theme = createTheme({
  typography: {
    fontFamily: "Noto Sans KR, Apple SD Gothic Neo, Roboto",
  },
  palette: {
    primary: {
      main: "#ff6c58",
      contrastText: "#fff",
    },
  },
  zIndex: { drawer: 990 },
});

const mainApi = new HttpMainApi();

function App() {
  const [userState, setUserState] = useState<userState>();

  async function setUserInfo() {
    const result = await AuthMain.checkAuthenticated();
    if (result) {
      userParam.isAuth = true;
      const userAttr = await AuthMain.getUserAttr();
      userParam.id = userAttr.email;
      userParam.name = userAttr.name;
      userParam.company = userAttr.company;
      if (superAdmin.includes(userAttr.email)) {
        userParam.isSuperAdmin = true;
      }
      const tInfo: any = await mainApi.get_tennant_info({
        tennant_id: userAttr.email,
      });
      if (tInfo.code === "200") {
        const res = await mainApi.get_tenant_resource({
          tenant_key: tInfo.response.tennant_info.tennant_key,
        });
        if (res.code === "200") {
          if (res.response.resource_info !== null) {
            userParam.apiUrl = res.response.resource_info.vectorDBApiEndpoint;
          }
        }
      }
    }
    setUserState(userParam);
  }

  useEffect(() => {
    setUserInfo();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {userState !== undefined && (
          <BrowserRouter>
            {/* <Header userState={userState} /> */}
            <MainConteiner userState={userState} />
          </BrowserRouter>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
