import * as React from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";

import { userState } from "../interface/MainInterface";

import MainPage from "../page/mainPage/mainPage";
import SignUp from "../page/signUp/SignUp";
import SignIn from "../page/signIn/SignIn";
import MngTennants from "../page/mngTennants/MngTennants";
import MyAccount from "../page/account/MyAccount";
import InfoMain from "../page/info/InfoMain";
import CostTotal from "../page/cost/CostTotal";
import CostExplore from "../page/cost/CostExplore";

interface propsType {
  userState: userState;
}

const MainConteiner = (props: propsType) => {
  let navigate = useNavigate();

  // 세션체크 및 선택된 메뉴 설정
  // const location = useLocation();
  // React.useEffect(() => {
  //   if (location.pathname !== "/login") {
  //     const userState = AuthMain.getSession();
  //     if (userState === false) {
  //       window.alert("세션이 종료되었습니다.\n다시 로그인해주세요.");
  //       window.location.href = "/login";
  //     }
  //   }

  //   props.setSelectedMenu(location.pathname);
  // }, [location, props]);

  return (
    <Box sx={{ p: 0 }}>
      <Routes>
        {props.userState.id !== "" ? (
          <Route path="/" element={<MyAccount userState={props.userState} />} />
        ) : (
          <Route path="/" element={<MainPage userState={props.userState} />} />
        )}
        <Route path="/main" element={<MainPage userState={props.userState} />} />
        <Route path="/signup" element={<SignUp userState={props.userState} />} />
        <Route path="/signin" element={<SignIn userState={props.userState} />} />
        <Route path="/mngTennants" element={<MngTennants userState={props.userState} />} />
        <Route path="/myAccount" element={<MyAccount userState={props.userState} />} />
        <Route path="/costTotal" element={<CostTotal userState={props.userState} />} />
        <Route path="/cost" element={<CostExplore userState={props.userState} />} />
        <Route path="/manual" element={<InfoMain userState={props.userState} />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Box>
  );
};

export default MainConteiner;
