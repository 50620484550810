import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import {
  DataGrid,
  GridColDef,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridRowSelectionModel,
  GridRenderCellParams,
  useGridApiRef,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { userState } from "../../interface/MainInterface";
import { useWindowSize } from "../../utils/useWindowSize";
import { HttpMainApi } from "../../interface/main-api";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import Header from "../../mainContainer/Header";
import BtnRequest from "./BtnRequest";

interface propsType {
  userState: userState;
}

interface IGridCols {
  pk: string;
  sk: string;
  name: string;
  company: string;
  tennant_status: string;
  onboarding_info: any;
  last_update_dtm: string;
  last_update_user_id: string;
}

const pageSize: number = 10;
const mainApi = new HttpMainApi();

const MngTennants = (props: propsType) => {
  const toastRef: any = useRef();
  const gridApi = useGridApiRef();
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: 10, page: 0 });
  const [dataRows, setDataRows] = useState<IGridCols[]>([]);
  const [gridHeight, setGridHeight] = useState(608);

  useEffect(() => {
    if (!props.userState.isSuperAdmin) {
      toastRef.current?.toast("권한이 없습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
      navigate("/");
      return;
    } else {
      fncGetTennantList();
    }

    return () => {
      setSelectedRow([]);
      setSelectedPage({ pageSize: 10, page: 0 });
      setDataRows([]);
      setIsLoading(false);
      setGridHeight(608);
    };
  }, []);

  const dataColumn: GridColDef[] = [
    {
      field: "cost",
      headerName: "Cost",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <Button
            variant="outlined"
            startIcon={<PointOfSaleIcon />}
            onClick={() => {
              fncCost(param.row);
            }}
          >
            Cost
          </Button>
        );
      },
    },
    { field: "sk", headerName: "Tennant", headerAlign: "center", align: "center", flex: 1 },
    { field: "name", headerName: "Name", headerAlign: "center", align: "center", flex: 1 },
    { field: "company", headerName: "Company", headerAlign: "center", align: "center", flex: 1 },
    { field: "tennant_status", headerName: "Status", headerAlign: "center", align: "center", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <BtnRequest info={param.row} fncGetTennantList={fncGetTennantList} />
          </>
        );
      },
    },
  ];

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={10}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  const fncGetTennantList = async () => {
    const param: any = {
      status: ["CREATE", "REQUEST", "COMPLETE", "DROPPING"],
    };
    setIsLoading(true);
    const res = await mainApi.get_tennant_list(param);
    console.log(res);
    if (res.code === "200") {
      setDataRows(res.response.tennant_list);
    }
    setIsLoading(false);
  };

  const fncCost = (userInfo: any) => {
    console.log("userInfo : ", userInfo);
    window.open(`/cost?tenantName=${userInfo.sk}`);
  };

  useEffect(() => {
    setGridHeight(windowSize.height - 280);
  }, [windowSize]);

  return (
    <>
      <Header userState={props.userState} backGround={"dark"} />
      <Box sx={{ pt: "70px", pl: "20px", pr: "20px" }}>
        <Box sx={{ p: 0.5 }}>
          <Stack spacing={2} direction="column">
            {/* Title */}
            <Box component={"div"}>
              <Typography variant="h4" gutterBottom>
                Tennants Menagement
              </Typography>
            </Box>
            {/* Title */}
            <Paper sx={{ p: 3, borderRadius: "1rem" }} elevation={3}>
              <Stack direction="column" spacing={2}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-start" }}></Grid>
                    <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button variant="contained" onClick={fncGetTennantList} startIcon={<SearchIcon />}>
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: "100%", height: gridHeight }}>
                  <DataGrid
                    apiRef={gridApi}
                    rows={dataRows}
                    columns={dataColumn}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: pageSize,
                        },
                      },
                      sorting: {
                        sortModel: [
                          {
                            field: "range_num",
                            sort: "asc",
                          },
                        ],
                      },
                    }}
                    slots={{ pagination: CustomPagination }}
                    hideFooterSelectedRowCount
                    getRowId={(row) => row.sk}
                    rowSelectionModel={selectedRow}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRow(newRowSelectionModel);
                    }}
                    paginationModel={selectedPage}
                    onPaginationModelChange={(newPagenationModel) => {
                      setSelectedPage(newPagenationModel);
                    }}
                  />
                </Box>
              </Stack>
            </Paper>
          </Stack>
        </Box>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default MngTennants;
