import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Auth, Hub } from "aws-amplify";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {
  userState: userState;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 3,
  pt: 1,
};

const ChangePwd = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const oldPwdRef: any = useRef();
  const userPwdRef: any = useRef();
  const userPwdConfirmRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [oldPwd, setOldPwd] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [userPwdConfirm, setUserPwdConfirm] = useState("");

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenModal(false);
      setOldPwd("");
      setUserPwd("");
      setUserPwdConfirm("");
    };
  }, []);

  const open = useCallback((open: boolean) => {
    setOldPwd("");
    setUserPwd("");
    setUserPwdConfirm("");
    setOpenModal(open);
  }, []);

  const fncChagePwd = async () => {
    if (oldPwd === "" || oldPwd === undefined) {
      toastRef.current?.toast("Please enter your old password.", "error", 3000, { vertical: "top", horizontal: "center" });
      oldPwdRef.current.focus();
      return;
    }
    if (userPwd === "" || userPwd === undefined) {
      toastRef.current?.toast("Please enter your new password.", "error", 3000, { vertical: "top", horizontal: "center" });
      userPwdRef.current.focus();
      return;
    }
    if (userPwdConfirm === "" || userPwdConfirm === undefined) {
      toastRef.current?.toast("Please enter new password again.", "error", 3000, { vertical: "top", horizontal: "center" });
      userPwdConfirmRef.current.focus();
      return;
    }
    if (userPwd.length < 6) {
      toastRef.current?.toast("Please enter password more than 6 char", "error", 3000, { vertical: "top", horizontal: "center" });
      userPwdRef.current.focus();
      return;
    }
    if (userPwd !== userPwdConfirm) {
      toastRef.current?.toast("Passwords do not match. (new & again)", "error", 3000, { vertical: "top", horizontal: "center" });
      userPwdConfirmRef.current.focus();
      return;
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(user, oldPwd, userPwd);
      console.log(data);
      toastRef.current?.toast("Success change your new password.", "success", 3000, { vertical: "top", horizontal: "center" });
      setOpenModal(false);
    } catch (err: any) {
      console.log(err);
      toastRef.current?.toast("" + err, "error", 3000, { vertical: "top", horizontal: "center" });
    }
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={0}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <CloseIcon className="color-red" />
              </IconButton>
            </Box>
            <Stack direction="column" spacing={2}>
              <TextField
                inputRef={oldPwdRef}
                value={oldPwd}
                placeholder="Enter your old password."
                variant="outlined"
                fullWidth
                required
                autoFocus
                type="password"
                autoComplete="current-password"
                inputProps={{ autoComplete: "new-password", enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setOldPwd(e.target.value);
                }}
              />
              <TextField
                inputRef={userPwdRef}
                value={userPwd}
                placeholder="Enter your new password."
                variant="outlined"
                fullWidth
                required
                type="password"
                autoComplete="current-password"
                inputProps={{ autoComplete: "new-password", enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setUserPwd(e.target.value);
                }}
              />
              <TextField
                inputRef={userPwdConfirmRef}
                value={userPwdConfirm}
                placeholder="Enter new password again."
                variant="outlined"
                fullWidth
                required
                type="password"
                autoComplete="current-password"
                inputProps={{ autoComplete: "new-password", enterKeyHint: "Enter", style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setUserPwdConfirm(e.target.value);
                }}
              />
              <Button variant="contained" fullWidth onClick={fncChagePwd}>
                Change Password
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(ChangePwd);
