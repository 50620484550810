import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import LogoLeft from "../../mainContainer/LogoLeft";
import InfoOverview from "./InfoOverview";
import InfoCreateAccount from "./InfoCreateAccount";
import InfoUsage from "./InfoUsage";
import IndexApi from "./IndexApi";
import DocumentApi from "./DocumentApi";

interface propsType {
  userState: userState;
}

const cssLeftButton: any = {
  m: "0 0 2px",
  pl: "32px",
  borderRadius: "4px",
  "&.Mui-selected": { backgroundColor: "rgb(40, 47, 92);", "&:hover": { backgroundColor: "rgb(40, 47, 92);" } },
  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1);" },
};

const InfoMain = (props: propsType) => {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(10);
  const [openGettingStarted, setOpenGettingStarted] = useState(true);
  const [openApiRef, setOpenApiRef] = useState(false);

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setSelectedIndex(10);
      setOpenGettingStarted(true);
      setOpenApiRef(false);
    };
  }, []);

  return (
    <>
      <Box sx={{ p: 0 }}>
        <Stack direction="row" spacing={0}>
          <Box className="left-menu-root">
            <LogoLeft />
            <nav aria-label="main mailbox folders">
              <List>
                {/* Getting started */}
                <ListItemButton
                  onClick={() => {
                    setOpenGettingStarted(!openGettingStarted);
                  }}
                >
                  {openGettingStarted ? <ExpandMore sx={{ color: "rgb(51, 153, 255)" }} /> : <KeyboardArrowRightIcon />}
                  <ListItemText
                    primary="Getting started"
                    primaryTypographyProps={{
                      fontWeight: "bold",
                    }}
                  />
                </ListItemButton>
                <Collapse in={openGettingStarted} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton selected={selectedIndex === 10} onClick={(event) => handleListItemClick(event, 10)} sx={cssLeftButton}>
                      <ListItemText
                        primary="Overview"
                        primaryTypographyProps={{
                          fontWeight: "bold",
                          pl: 1,
                          ...(selectedIndex === 10 ? { color: "rgb(51, 153, 255)" } : { color: "rgb(176, 184, 196)" }),
                        }}
                      />
                    </ListItemButton>
                    <ListItemButton selected={selectedIndex === 11} onClick={(event) => handleListItemClick(event, 11)} sx={cssLeftButton}>
                      <ListItemText
                        primary="Create Account"
                        primaryTypographyProps={{
                          fontWeight: "bold",
                          pl: 1,
                          ...(selectedIndex === 11 ? { color: "rgb(51, 153, 255)" } : { color: "rgb(176, 184, 196)" }),
                        }}
                      />
                    </ListItemButton>
                    <ListItemButton selected={selectedIndex === 11} onClick={(event) => handleListItemClick(event, 12)} sx={cssLeftButton}>
                      <ListItemText
                        primary="Usage"
                        primaryTypographyProps={{
                          fontWeight: "bold",
                          pl: 1,
                          ...(selectedIndex === 12 ? { color: "rgb(51, 153, 255)" } : { color: "rgb(176, 184, 196)" }),
                        }}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                {/* API Reference */}
                <ListItemButton
                  onClick={() => {
                    setOpenApiRef(!openApiRef);
                  }}
                >
                  {openApiRef ? <ExpandMore sx={{ color: "rgb(51, 153, 255)" }} /> : <KeyboardArrowRightIcon />}
                  <ListItemText
                    primary="API Reference"
                    primaryTypographyProps={{
                      fontWeight: "bold",
                    }}
                  />
                </ListItemButton>
                <Collapse in={openApiRef} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton selected={selectedIndex === 21} onClick={(event) => handleListItemClick(event, 21)} sx={cssLeftButton}>
                      <ListItemText
                        primary="Index"
                        primaryTypographyProps={{
                          fontWeight: "bold",
                          pl: 1,
                          ...(selectedIndex === 21 ? { color: "rgb(51, 153, 255)" } : { color: "rgb(176, 184, 196)" }),
                        }}
                      />
                    </ListItemButton>
                    <ListItemButton selected={selectedIndex === 22} onClick={(event) => handleListItemClick(event, 22)} sx={cssLeftButton}>
                      <ListItemText
                        primary="Document"
                        primaryTypographyProps={{
                          fontWeight: "bold",
                          pl: 1,
                          ...(selectedIndex === 22 ? { color: "rgb(51, 153, 255)" } : { color: "rgb(176, 184, 196)" }),
                        }}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                {/* API Reference */}
              </List>
            </nav>
          </Box>
          <Box sx={{ width: "100%" }}>
            {selectedIndex === 10 && <InfoOverview />}
            {selectedIndex === 11 && <InfoCreateAccount />}
            {selectedIndex === 12 && <InfoUsage />}
            {selectedIndex === 21 && <IndexApi />}
            {selectedIndex === 22 && <DocumentApi />}
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default InfoMain;
