import { CodeBlock, dracula } from "react-code-blocks";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface propsType {
  move: any;
}

const create_index: string = `import axios from "axios";

const requestPost => async (requestBody: any) => {
  let header: any = { "Content-Type": "application/json" };
  const response = await axios.post(<USER API URL>, requestBody, header);
  return response.data.body;
}

const createIndex => async () => {
    const param: any = {
        command: "create",
        type: "index",
        name: "test-index-01",
        body: {
            settings: {
                knn: true,
                "knn.algo_param.ef_search": 100,
            },
            mappings: {
                properties: {
                    id: {
                        type: "text",
                    },
                    text: {
                        type: "text",
                    },
                    metadata: {
                        type: "text",
                    },
                    embedding: {
                        type: "knn_vector",
                        dimension: 1024, // naver : 1024, openai : 1536
                        method: {
                          name: "hnsw",
                          space_type: "l2",
                          engine: "nmslib",
                          parameters: {
                              ef_construction: 128,
                              m: 24,
                          },
                        }
                    },
                },
            },
        },
    };

    const response = await requestPost(param)
}`;

const create_index_response: string = `{
    "statusCode": 200,
    "body": {
        "status_code": 200,
        "error_msg": "",
        "result": ""
    }
}`;

const CreateIndexApi = (props: propsType) => {
  return (
    <Box className="info-main-content">
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box className="info-main-content-sub-root">
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography gutterBottom className="info-main-content-sub-title">
              Create Index
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                props.move("top");
              }}
            >
              [TOP]
            </Button>
          </Box>
        </Box>
        <Divider sx={{ width: "100%" }} />
        <Typography gutterBottom className="info-main-request-body-text mt-20">
          인덱스를 생성한다.
        </Typography>
        <Typography gutterBottom className="info-main-request-body-title mt-20">
          Request
        </Typography>
        <Box>
          <CodeBlock text={create_index} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
        </Box>
        <Typography gutterBottom className="info-main-request-body-title mt-20">
          Parameters
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • command (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 생성 ("create")
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • type (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 인덱스 ("index")
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • name (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 생성할 인덱스명. (소문자, 영문, 숫자, -)
        </Typography>
        <Typography gutterBottom className="info-main-request-body-title mt-20">
          Response
        </Typography>
        <Box>
          <CodeBlock text={create_index_response} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
        </Box>
      </Box>
    </Box>
  );
};

export default CreateIndexApi;
