import { useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";

const Logo = () => {
  let navigate = useNavigate();
  const goHome = () => {
    navigate("/main");
  };

  return (
    <>
      <IconButton color="inherit" onClick={goHome} sx={{ justifyContent: "flex-start" }}>
        <CardMedia component="img" image={"/images/hiv-home.png"} sx={{ width: 130, height: 36 }} />
      </IconButton>
    </>
  );
};

export default Logo;
