import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface propsType {}

const InfoCreateAccount = (props: propsType) => {
  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0} sx={{ height: "100vh", overflow: "auto" }}>
          <Box className="info-main-header-root">
            <Typography variant="h5" gutterBottom className="info-main-header-title">
              Create Account
            </Typography>
          </Box>
          <Divider />
          <Box className="info-main-content-root">
            <div id="top" className="width-100">
              <Box className="info-main-content-header">
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography gutterBottom className="info-main-content-title">
                    H.I.Vector 서비스의 사용을 위해서는 회원가입이 필요합니다. 회원가입 후 생성되는 고유 API 주소를 통해서 벡터 데이터베이스
                    명령을 수행할 수 있습니다.
                  </Typography>
                </Box>
              </Box>
            </div>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      SignUp
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  사이트 우측 상단의 SIGNUP 버튼을 클릭하여 회원가입 페이지로 이동합니다.
                </Typography>
                <CardMedia component="img" image={"/images/signup-01.png"} />
                <Box sx={{ mt: 4 }}></Box>
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  이메일, 패스워드를 입력하고 사용자명 및 회사명을 입력하세요.
                </Typography>
                <CardMedia component="img" image={"/images/signup-02.png"} sx={{ width: "50%" }} />
                <Box sx={{ mt: 4 }}></Box>
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  등록된 이메일로 발송된 인증번호를 입력하세요.
                </Typography>
                <CardMedia component="img" image={"/images/verify-01.png"} sx={{ width: "50%" }} />
                <Box sx={{ mt: 4 }}></Box>
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  인증이 완료되면 나의 정보에서 API 주소와 필요한 정보를 확인할 수 있습니다.
                </Typography>
                <CardMedia component="img" image={"/images/my-account-01.png"} />
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default InfoCreateAccount;
