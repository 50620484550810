export interface userState {
  id: string;
  name: string;
  company: string;
  isAuth: boolean;
  isSuperAdmin: boolean;
  apiUrl: string;
}

export interface tennantInfo {
  pk: string;
  sk: string;
  name: string;
  company: string;
  tennant_key: string;
  tennant_status: string;
}

export let emptyTennantInfo: tennantInfo = {
  pk: "",
  sk: "",
  name: "",
  company: "",
  tennant_key: "",
  tennant_status: "",
};

export const systemIndex: any = [".opensearch-observability", ".kibana_1"];
export const xSearchAuth: any = [
  "gpthealth@hyundai-ite.com",
  "bluegury@hyundai-ite.com",
  "gptvoc@hyundai-ite.com",
  "gptjellbbo@hyundai-ite.com",
  "ai-news@hyundai-ite.com",
];
