import { Auth } from "aws-amplify";

export async function checkAuthenticated() {
  let result = false;
  await Auth.currentSession()
    .then(async (data) => {
      await Auth.currentAuthenticatedUser()
        .then((data) => {
          result = true;
        })
        .catch((err) => {
          result = false;
        });
    })
    .catch((err) => {
      result = false;
    });
  return result;
}

export async function getUserAttr() {
  try {
    if (!(await checkAuthenticated())) throw Error("user not loged in");
    const user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    let rtn_pay_load = {
      email: attributes.email,
      name: attributes.name,
      company: attributes["custom:company"],
    };
    return rtn_pay_load;
  } catch (e) {
    return {
      email: "",
      name: "",
      company: "",
    };
  }
}

export async function checkAdmin() {
  // if (!(await checkAuthenticated())) return false;
  // const user = await Auth.currentAuthenticatedUser();
  // const res = await commonApi.get_admin_info(user.attributes.email);
  // if (res.result_code !== "200") return false;
  return true;
}

export async function setChatName(newChatName: string) {
  let loginUser = await Auth.currentAuthenticatedUser();
  let result = await Auth.updateUserAttributes(loginUser, {
    "custom:chat_name": newChatName,
  });
  return result;
}

export async function getChatName() {
  let loginUser = await Auth.currentAuthenticatedUser();
  const { attributes } = loginUser;
  if (attributes["custom:chat_name"] === undefined || attributes["custom:chat_name"] === "") {
    return "";
  } else {
    return attributes["custom:chat_name"];
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
    return true;
  } catch (error) {
    console.error("error signing out: ", error);
    return false;
  }
}

export async function setPicture(path: string) {
  let loginUser = await Auth.currentAuthenticatedUser();
  let result = await Auth.updateUserAttributes(loginUser, {
    picture: path,
  });
  return result;
}
