import dayjs from "dayjs";
import * as XLSX from "xlsx";

export class CommonUtils {
  public dateFormat = (value: string, iPattern: string, oPattern: string) => {
    return dayjs(value, iPattern).format(oPattern);
  };

  public convertSecToTime = (timeInSeconds: number) => {
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
    return {
      hours: result.substr(0, 2),
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2),
    };
  };

  public isEmptyObj(obj: any) {
    if (obj === null || obj === undefined) return true;
    if (obj.constructor === Object && Object.keys(obj).length > 0) {
      return false;
    }

    return true;
  }

  public numericComma = (value: any) => {
    if (typeof value === "number") {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return undefined;
  };

  public downloadExcel = (sheetContent: any, colWidth: any, sheetName: string, fileName: string) => {
    const sheet: any = XLSX.utils.aoa_to_sheet(sheetContent);
    sheet["!cols"] = colWidth;
    const chatFile = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(chatFile, sheet, sheetName);
    XLSX.writeFile(chatFile, fileName);
  };
}
