import { CodeBlock, dracula } from "react-code-blocks";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface propsType {
  move: any;
}

const request_code: string = `import axios from "axios";

const requestPost => async (requestBody: any) => {
  let header: any = { "Content-Type": "application/json" };
  const response = await axios.post(<USER API URL>, requestBody, header);
  return response.data.body;
}

const deleteIndex => async () => {
    const param: any = {
        command: "delete",
        type: "index",
        name: "test-index-01"
    };

    const response = await requestPost(param)
}`;

const response_code: string = `{
    "statusCode": 200,
    "body": {
        "status_code": 200,
        "error_msg": "",
        "rtn_msg": {
            "acknowledged": true
        },
        "result": ""
    }
}`;

const DeleteIndexApi = (props: propsType) => {
  return (
    <Box className="info-main-content">
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box className="info-main-content-sub-root">
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography gutterBottom className="info-main-content-sub-title">
              Delete Index
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                props.move("top");
              }}
            >
              [TOP]
            </Button>
          </Box>
        </Box>
        <Divider sx={{ width: "100%" }} />
        <Typography gutterBottom className="info-main-request-body-text mt-20">
          인덱스를 삭제한다.
        </Typography>
        <Typography gutterBottom className="info-main-request-body-title mt-20">
          Request
        </Typography>
        <Box>
          <CodeBlock text={request_code} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
        </Box>
        <Typography gutterBottom className="info-main-request-body-title mt-20">
          Parameters
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • command (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 삭제 ("delete")
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • type (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 인덱스 ("index")
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • name (string) <span style={{ color: "red" }}>[REQUIRED]</span>: 삭제할 인덱스명
        </Typography>
        <Typography gutterBottom className="info-main-request-body-title mt-20">
          Response
        </Typography>
        <Box>
          <CodeBlock text={response_code} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteIndexApi;
