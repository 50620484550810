import { ApiBase } from "./api-base";

export class HttpMainApi extends ApiBase {
  onApiName(): string {
    return "MainApi";
  }

  onUrlPath(): string {
    return "/api";
  }

  public async get_tennant_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_tennant_list";
    const response = await this.requestPost(requestBody);
    return response;
  }

  public async get_tennant_info(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_tennant_info";
    const response = await this.requestPost(requestBody);
    return response;
  }

  public async update_tenant_status(param: any) {
    let requestBody: any = param;
    requestBody.command = "update_tenant_status";
    const response = await this.requestPost(requestBody);
    return response;
  }

  public async get_tenant_resource(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_tenant_resource";
    const response = await this.requestPost(requestBody);
    return response;
  }

  public async naver_embedding(param: any) {
    let requestBody: any = param;
    requestBody.command = "naver_embedding";
    console.log("requestBody : ", requestBody);
    const response = await this.requestPost(requestBody);
    if (response.code === "200") {
      return response.response.embedding;
    } else {
      console.error("naver_embedding : ", response.error_msg);
      return null;
    }
  }

  public async get_cost_report(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_cost_report";
    const response = await this.requestPost(requestBody);
    return response;
  }

  public async create_api_key(param: any) {
    let requestBody: any = param;
    requestBody.command = "create_api_key";
    const response = await this.requestPost(requestBody);
    return response;
  }

  public async delete_api_key(param: any) {
    let requestBody: any = param;
    requestBody.command = "delete_api_key";
    const response = await this.requestPost(requestBody);
    return response;
  }
}
