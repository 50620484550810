import axios from "axios";

export class HttpCustomApi {
  protected apiUrlPath: string | undefined;

  constructor(url: string | undefined) {
    this.apiUrlPath = url;
  }

  protected async requestPostAxios(resource: string, requestBody: any): Promise<any> {
    try {
      let header: any = { timeout: 60000 };
      const response = await axios.post(this.apiUrlPath + "/" + resource, requestBody, header);
      return response.data.body;
    } catch (error) {
      console.error(error);
      return { code: "500", response: { error_msg: error } };
    }
  }

  protected async requestPostAxiosWithKey(resource: string, requestBody: any, tenant_key: string): Promise<any> {
    try {
      let header: any = { headers: { "x-api-key": tenant_key }, timeout: 60000 };
      const response = await axios.post(this.apiUrlPath + "/" + resource, requestBody, header);
      return response.data;
    } catch (error) {
      console.error(error);
      return { code: "500", response: { error_msg: error } };
    }
  }

  public async common_request(resource: string, param: any) {
    const response = await this.requestPostAxios(resource, param);
    return response;
  }

  public async auth_request(resource: string, param: any, tenant_key: string) {
    const response = await this.requestPostAxiosWithKey(resource, param, tenant_key);
    return response;
  }
}
