import { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {
  userState: userState;
  tennantInfo: any;
  resource: any;
  get_tennant_info: any;
}

const ApiKeys = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [masking, setMasking] = useState(true);

  const toggleMasking = () => {
    setMasking((masking) => !masking);
  };

  const maskKey = (text: string) => {
    const vals = text.split("-");

    for (let i = 0; i < vals.length; i++) {
      vals[i] = "*".repeat(vals[i].length);
    }

    return vals.join("-");
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setMasking(true);
    };
  }, []);

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className="my-account-header-root">
            <Typography
              variant="h5"
              gutterBottom
              className="my-account-header-title"
            >
              Application API Keys
            </Typography>
          </Box>
          <Divider />
          <Box className="my-account-content-root">
            <Box className="apikey-content-header">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  gutterBottom
                  className="my-account-content-sub-title"
                >
                  Manage API Keys in Application here
                </Typography>
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table className="data-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="data-table-header">Name</TableCell>
                    <TableCell className="data-table-header">Value</TableCell>
                    <TableCell className="data-table-header" align="center">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.resource !== null && (
                    <>
                      <TableRow
                        key={props.resource.sk}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Tenant Key"}
                        </TableCell>
                        <TableCell sx={{ fontFamily: "monospace" }}>
                          {masking
                            ? maskKey(props.tennantInfo.tennant_key)
                            : props.tennantInfo.tennant_key}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={toggleMasking}>
                            {masking ? (
                              <VisibilityIcon className="data-table-img-button" />
                            ) : (
                              <VisibilityOffIcon className="data-table-img-button" />
                            )}
                          </IconButton>
                          <CopyToClipboard
                            text={props.tennantInfo.tennant_key}
                            onCopy={() => {
                              toastRef.current?.toast(
                                "Copy to clipboard is complete.",
                                "success",
                                3000,
                                {
                                  vertical: "top",
                                  horizontal: "center",
                                }
                              );
                            }}
                          >
                            <IconButton>
                              <ContentCopyIcon className="data-table-img-button" />
                            </IconButton>
                          </CopyToClipboard>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"vectorDBApiEndpoint"}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"API URL"}
                        </TableCell>
                        <TableCell sx={{ fontFamily: "monospace" }}>
                          {process.env.REACT_APP_VECTOR_CORE + "/api"}
                        </TableCell>
                        <TableCell align="center">
                          <CopyToClipboard
                            text={process.env.REACT_APP_VECTOR_CORE + "/api"}
                            onCopy={() => {
                              toastRef.current?.toast(
                                "Copy to clipboard is complete.",
                                "success",
                                3000,
                                {
                                  vertical: "top",
                                  horizontal: "center",
                                }
                              );
                            }}
                          >
                            <IconButton>
                              <ContentCopyIcon className="data-table-img-button" />
                            </IconButton>
                          </CopyToClipboard>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default ApiKeys;
