import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DataGrid, GridRenderCellParams, GridCellParams } from "@mui/x-data-grid";

import { userState } from "../../interface/MainInterface";
import { useWindowSize } from "../../utils/useWindowSize";
import { CommonUtils } from "../../utils/common_utils";
import { HttpMainApi } from "../../interface/main-api";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import Header from "../../mainContainer/Header";

interface propsType {
  userState: userState;
}

interface IGridCols {
  pk: string;
  sk: string;
  name: string;
  company: string;
  tennant_status: string;
  onboarding_info: any;
  last_update_dtm: string;
  last_update_user_id: string;
}

const mainApi = new HttpMainApi();

const CostTotal = (props: propsType) => {
  const toastRef: any = useRef();
  const windowSize = useWindowSize();
  const cUtils = new CommonUtils();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [gridHeight, setGridHeight] = useState(608);
  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().startOf("month"));
  const [usageList, setUseageList] = useState<any[]>([]);

  useEffect(() => {
    if (!props.userState.isSuperAdmin) {
      toastRef.current?.toast("권한이 없습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
      navigate("/");
      return;
    } else {
      getCostReport();
    }
    return () => {
      setIsLoading(false);
      setGridHeight(608);
      setSrchDateFrom(dayjs().startOf("month"));
      setUseageList([]);
    };
  }, []);

  const columns = [
    {
      field: "service",
      sortable: false,
      headerName: "서비스",
      flex: 1,
      minWidht: 300,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{param.row.sort2 === "1" ? "" : param.row.service}</span>
          </>
        );
      },
    },
    { field: "usage_type", sortable: false, headerName: "타입", flex: 1, minWidht: 300 },
    {
      field: "UnblendedCost_amount",
      sortable: false,
      headerName: "청구금액",
      width: 200,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{cUtils.numericComma(param.row.UnblendedCost_amount)}</span>
          </>
        );
      },
    },
    {
      field: "UnblendedCost_unit",
      sortable: false,
      headerName: "단위",
      width: 200,
    },
    {
      field: "UsageQuantity_amount",
      sortable: false,
      headerName: "사용량",
      width: 200,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{cUtils.numericComma(param.row.UsageQuantity_amount)}</span>
          </>
        );
      },
    },
    {
      field: "UsageQuantity_unit",
      sortable: false,
      headerName: "단위",
      width: 200,
    },
  ];

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };

  const getCostReport = async () => {
    const param: any = {
      search_date_from: dayjs(srchDateFrom).format("YYYY-MM-DD"),
      search_date_to: dayjs(srchDateFrom).endOf("month").format("YYYY-MM-DD"),
    };
    setIsLoading(true);
    const res = await mainApi.get_cost_report(param);
    if (res.code === "200") {
      setUseageList(res.response.cost_list);
      if (res.response.cost_list.length === 0)
        toastRef.current?.toast("발생된 비용이 없습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  const downloadExcelList = () => {
    if (usageList.length === 0) {
      toastRef.current?.toast("다운로드할 데이터가 없습니다.", "error", 3000);
      return;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 300 }, { wpx: 300 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];
    // sheet header
    let statisticsHistory: any = [["서비스", "타입", "청구금액", "단위", "사용량", "단위"]];

    // 내용 생성
    for (const item of usageList) {
      const detail = [
        item.service,
        item.usage_type,
        item.UnblendedCost_amount,
        item.UnblendedCost_unit,
        item.UsageQuantity_amount,
        item.UsageQuantity_amount,
      ];
      statisticsHistory = [...statisticsHistory, detail];
    }

    const statsticsSheetName = "월별사용량";
    // 파일명
    const fileName = `[${dayjs(srchDateFrom).format("YYYY-MM")}] HIVec-월별사용량통계.xlsx`;
    cUtils.downloadExcel(statisticsHistory, colWidth, statsticsSheetName, fileName);
  };

  useEffect(() => {
    setGridHeight(windowSize.height - 280);
  }, [windowSize]);

  return (
    <>
      <Header userState={props.userState} backGround={"dark"} />
      <Box sx={{ pt: "70px", pl: "20px", pr: "20px" }}>
        <Box sx={{ p: 0.5 }}>
          <Stack spacing={2} direction="column">
            {/* Title */}
            <Box component={"div"}>
              <Typography variant="h4" gutterBottom>
                Total Cost Explore
              </Typography>
            </Box>
            {/* Title */}
            <Box>
              <Stack direction="column" spacing={2}>
                {/* 검색조건 */}
                <Box>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="방송일From"
                        views={["year", "month"]}
                        openTo="month"
                        value={srchDateFrom}
                        onChange={handleSrchDateFromChange}
                        slotProps={{ textField: { size: "small", autoComplete: "off" } }}
                      />
                    </LocalizationProvider>
                    <Button variant="contained" size="small" onClick={getCostReport}>
                      조회
                    </Button>
                    <Button variant="contained" size="small" onClick={downloadExcelList}>
                      EXCEL
                    </Button>
                  </Stack>
                </Box>
                {/* 검색조건 */}
                {/* 그리드 */}
                <Box>
                  <Paper
                    sx={{
                      height: gridHeight,
                      p: 2,
                      "& .data": {
                        backgroundColor: "#b9d5ff91",
                        color: "#1a3e72",
                      },
                      "& .sub-total": {
                        backgroundColor: "#ff943975",
                        color: "#1a3e72",
                      },
                      "& .total": {
                        backgroundColor: "#ff393975",
                        color: "#1a3e72",
                      },
                    }}
                    elevation={5}
                  >
                    <DataGrid
                      rows={usageList}
                      rowHeight={40}
                      columns={columns}
                      pagination
                      getRowId={(row) => `${row.service}-${row.usage_type}`}
                      getCellClassName={(params: GridCellParams<any, any, any>) => {
                        if (params.row.sort === "0") {
                          return "total";
                        } else {
                          if (params.row.sort2 === "0") return "sub-total";
                          else return "data";
                        }
                      }}
                    />
                  </Paper>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default CostTotal;
