import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import CreateIndexApi from "./index/CreateIndexApi";
import GetIndexApi from "./index/GetIndexApi";
import ListIndexApi from "./index/ListIndexApi";
import DeleteIndexApi from "./index/DeleteIndexApi";

interface propsType {}

const IndexApi = (props: propsType) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const move = (id: string) => {
    window.location.href = `#${id}`;
  };

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0} sx={{ height: "100vh", overflow: "auto" }}>
          <Box className="info-main-header-root">
            <Typography variant="h5" gutterBottom className="info-main-header-title">
              Index APIs
            </Typography>
          </Box>
          <Divider />
          <Box className="info-main-content-root">
            <div id="top" className="width-100">
              <Box className="info-main-content-header">
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography gutterBottom className="info-main-content-title">
                    Vector Database에서 사용할 Index를 관리하기 위한 API Reference입니다. Index를 생성, 수정, 삭제 및 목록을 조회할 수
                    있습니다.
                  </Typography>
                </Box>
              </Box>
            </div>
            <div id="createIndex" className="width-100">
              <List sx={{ width: "100%", color: "green" }} component="nav" aria-labelledby="index">
                <ListItemButton onClick={handleClick} sx={{ pl: 0 }}>
                  {open ? <ExpandMore /> : <KeyboardArrowRightIcon />}
                  <ListItemText primary="목차" />
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        move("createIndex");
                      }}
                    >
                      <ListItemText primary="인덱스 생성" />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        move("getIndex");
                      }}
                    >
                      <ListItemText primary="인덱스 정보 조회" />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        move("listIndex");
                      }}
                    >
                      <ListItemText primary="인덱스 목록 조회" />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        move("deleteIndex");
                      }}
                    >
                      <ListItemText primary="인덱스 삭제" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </div>
            <div id="createIndex" className="width-100">
              <CreateIndexApi move={move} />
            </div>
            <div id="getIndex" className="width-100">
              <GetIndexApi move={move} />
            </div>
            <div id="listIndex" className="width-100">
              <ListIndexApi move={move} />
            </div>
            <div id="deleteIndex" className="width-100">
              <DeleteIndexApi move={move} />
            </div>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default IndexApi;
