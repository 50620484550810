import { API, Auth } from "aws-amplify";

export abstract class ApiBase {
  protected apiName: string;
  protected urlPath: string;
  protected authToken: string | undefined;

  abstract onApiName(): string;
  abstract onUrlPath(): string;

  constructor() {
    this.apiName = this.onApiName();
    this.urlPath = this.onUrlPath();
    this.authToken = undefined;
  }

  protected async getToken() {
    if (this.authToken === undefined) {
      const session = await Auth.currentSession();
      this.authToken = `Bearer ${session.getIdToken().getJwtToken()}`;
    }

    return this.authToken;
  }

  protected async requestPost(requestBody: any): Promise<any> {
    let header: any = {};
    let authToken: string;

    if (this.apiName === "MainApi") {
      authToken = await this.getToken();

      header = {
        Authorization: authToken,
      };
    }

    const request = {
      headers: header,
      body: requestBody,
    };
    const response = await API.post(this.apiName, this.urlPath, request);
    return response;
  }
}
