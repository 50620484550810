import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { HttpCustomApi } from "../../interface/custom-api";
import { userState, tennantInfo } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {
  userState: userState;
  tennantInfo: tennantInfo;
  getIndexes: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "4px",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 3,
  pt: 1,
};

const cosMethod = {
  name: "hnsw",
  space_type: "cosinesimil",
  engine: "nmslib",
};

const l2Method = {
  name: "hnsw",
  space_type: "l2",
  engine: "nmslib",
  parameters: {
    ef_construction: 128,
    m: 24,
  },
};

const vectoCoreApi = new HttpCustomApi(process.env.REACT_APP_VECTOR_CORE);

const CreateIndex = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const indexNameRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [indexName, setIndexName] = useState("");

  const [modelType, setModelType] = useState("naverX");

  const handleModelChange = (event: SelectChangeEvent) => {
    setModelType(event.target.value as string);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenModal(false);
      setIndexName("");
      setModelType("openai");
    };
  }, []);

  const open = useCallback((open: boolean) => {
    setIndexName("");
    setOpenModal(open);
  }, []);

  const fncCreateIndex = async () => {
    if (indexName === "" || indexName === undefined) {
      toastRef.current?.toast("Please enter Index name.", "error", 3000, { vertical: "top", horizontal: "center" });
      indexNameRef.current.focus();
      return;
    }

    let selectedMethod = {};
    let dimension = 0;
    if (modelType === "naverL2" || modelType === "naverX") {
      selectedMethod = l2Method;
      dimension = 1024;
    } else if (modelType === "naverCos") {
      selectedMethod = cosMethod;
      dimension = 1024;
    } else if (modelType === "openaiL2") {
      selectedMethod = l2Method;
      dimension = 1536;
    } else if (modelType === "openaiCos") {
      selectedMethod = cosMethod;
      dimension = 1536;
    }

    const param: any = {
      command: "create_index",
      index_name: `${modelType}-${indexName}`.toLowerCase(),
      index_body: {
        settings: {
          knn: true,
          "knn.algo_param.ef_search": 100,
        },
        mappings: {
          properties: {
            id: {
              type: "text",
            },
            text: {
              type: "text",
            },
            metadata: {
              type: "text",
            },
            embedding: {
              type: "knn_vector",
              dimension: dimension,
              method: selectedMethod,
            },
          },
        },
      },
    };

    setIsLoading(true);
    const res = await vectoCoreApi.auth_request("api", param, props.tennantInfo.tennant_key);
    console.log("create_index RES : ", res);
    if (res.code === "200") {
      toastRef.current?.toast("Success create index", "success", 3000, { vertical: "top", horizontal: "center" });
      props.getIndexes();
      setOpenModal(false);
    } else {
      toastRef.current?.toast("Error : " + res.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
    }

    setIsLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={0}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <CloseIcon className="color-red" />
              </IconButton>
            </Box>
            <Stack direction="column" spacing={2}>
              <TextField
                id="txtIndexName"
                inputRef={indexNameRef}
                value={indexName}
                placeholder="Enter Index name."
                variant="outlined"
                autoFocus
                fullWidth
                required
                inputProps={{ style: { padding: "12px 16px 10px 16px" } }}
                onChange={(e) => {
                  setIndexName(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") fncCreateIndex();
                }}
              />
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="comboEmbeddingType">Embedding Mode</InputLabel>
                  <Select id="ebdModel" value={modelType} label="Embedding Model" size="small" onChange={handleModelChange}>
                    <MenuItem value={"naverL2"}>Naver-l2</MenuItem>
                    <MenuItem value={"naverX"}>Naver-x</MenuItem>
                    <MenuItem value={"naverCos"}>Naver-cos</MenuItem>
                    <MenuItem value={"openaiL2"}>OpenAI-l2</MenuItem>
                    <MenuItem value={"openaiCos"}>OpenAI-cos</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Button variant="contained" fullWidth onClick={fncCreateIndex}>
                Create Index
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(CreateIndex);
