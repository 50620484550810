import { CodeBlock, dracula } from "react-code-blocks";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface propsType {
  move: any;
}

const request_code: string = `import axios from "axios";

const requestPost => async (requestBody: any) => {
  let header: any = { "Content-Type": "application/json" };
  const response = await axios.post(<USER API URL>, requestBody, header);
  return response.data.body;
}

const getDoc => async () => {
    const param: any = {
        command: "get",
        type: "doc",
        name: "test-index-01",
        body: {
            "answer_num" : 2, // 가져올 Ranking 숫자
            "vector" : 벡터데이터,
            "vector_column_name" : "벡터컬럼이름",
            "vector_num" : 2 // knn 숫자
        }
    };

    const response = await requestPost(param)
}`;

const response_code: string = `{
    "statusCode": 200,
    "body": {
        "status_code": 200,
        "error_msg": "",
        "rtn_msg": "",
        "max_score": 0.031011762,
        "result": [
            {
                "id": "test-01",
                "text": <원본 TEXT>,
                "metadata": "{ 'source':영양json.txt, 'page':'0'}",
                "embedding": [ <vectors>... ],
                "score": 0.031011762
            }, ...
        ]
    }
}`;

const GetDocApi = (props: propsType) => {
  return (
    <Box className="info-main-content">
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box className="info-main-content-sub-root">
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography gutterBottom className="info-main-content-sub-title">
              Get Document
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                props.move("top");
              }}
            >
              [TOP]
            </Button>
          </Box>
        </Box>
        <Divider sx={{ width: "100%" }} />
        <Typography gutterBottom className="info-main-request-body-text mt-20">
          도큐먼트(ID로)를 조회한다.
        </Typography>
        <Typography gutterBottom className="info-main-request-body-title mt-20">
          Request
        </Typography>
        <Box>
          <CodeBlock text={request_code} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
        </Box>
        <Typography gutterBottom className="info-main-request-body-title mt-20">
          Parameters
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • command (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 생성 ("create")
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • type (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 도큐먼트 ("doc")
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • name (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 데이터를 저장할 인덱스명.
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text">
          • body (json) <span style={{ color: "red" }}>[REQUIRED]</span> : 도큐먼트 spec.
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text-sub">
          - id (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 도큐먼트 ID (소문자, 영문, -)
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text-sub">
          - text (string) <span style={{ color: "red" }}>[REQUIRED]</span> : 원본 TEXT
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text-sub">
          - metadata (string) [optional] : FREE FORMAT TEXT
        </Typography>
        <Typography gutterBottom className="info-main-request-body-text-sub">
          - embedding (vector) <span style={{ color: "red" }}>[REQUIRED]</span> : 원본 TEXT를 embedding한 vector값
        </Typography>
        <Typography gutterBottom className="info-main-request-body-title mt-20">
          Response
        </Typography>
        <Box>
          <CodeBlock text={response_code} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
        </Box>
      </Box>
    </Box>
  );
};

export default GetDocApi;
