import { CodeBlock, dracula } from "react-code-blocks";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface propsType {}

const request_code: string = `import axios from "axios";

const requestPost => async (requestBody: any) => {
  let header: any = { "Content-Type": "application/json" };
  const response = await axios.post(<USER API URL>, requestBody, header);
  return response.data.body;
}

const createDoc => async () => {
    const param: any = {
        command: "create",
        type: "doc",
        name: "test-index-01",
        body: {
            "id": "test-01",
            "text": "hello world",
            "metadata": "{ 'source':'test-01', 'page':'01'}" // free format
            "embedding": [ <vecrots> ]
        }
    };

    const response = await requestPost(param)
}`;

const request_query: string = `import axios from "axios";

const requestPost => async (requestBody: any) => {
  let header: any = { "Content-Type": "application/json" };
  const response = await axios.post(<USER API URL>, requestBody, header);
  return response.data.body;
}

const getDoc => async () => {
    const param: any = {
        command: "get",
        type: "doc",
        name: "test-index-01",
        body: {
            "answer_num" : 2, // 가져올 Ranking 숫자
            "vector" : 벡터데이터,
            "vector_column_name" : "벡터컬럼이름",
            "vector_num" : 2 // knn 숫자
        }
    };

    const response = await requestPost(param)
}`;

const response_query: string = `{
    "statusCode": 200,
    "body": {
        "status_code": 200,
        "error_msg": "",
        "rtn_msg": "",
        "max_score": 0.031011762,
        "result": [
            {
                "id": "test-01",
                "text": <원본 TEXT>,
                "metadata": "{ 'source':영양json.txt, 'page':'0'}",
                "embedding": [ <vectors>... ],
                "score": 0.031011762
            }, ...
        ]
    }
}`;

const InfoOverview = (props: propsType) => {
  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0} sx={{ height: "100vh", overflow: "auto" }}>
          <Box className="info-main-header-root">
            <Typography variant="h5" gutterBottom className="info-main-header-title">
              OverView
            </Typography>
          </Box>
          <Divider />
          <Box className="info-main-content-root">
            <div id="top" className="width-100">
              <Box className="info-main-content-header">
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography gutterBottom className="info-main-content-title">
                    Overview of Hyundai IT&E Vector Database
                  </Typography>
                </Box>
              </Box>
            </div>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      H.I.Vector Overview
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  H.I.Vector를 사용하면 초거대 AI에서 필요한 Embedding 데이터를 손쉽게 저장하고 쿼리할 수 있습니다. 또한 AWS Cloud를
                  이용하여 별도의 인프라를 설치할 필요없이 완전 관리형으로 사용가능한 클라우드 기반 벡터 데이터베이스 입니다. H.I.Vector는
                  대용량의 벡터 규모에 대해서 짧은 대기 시간으로 쿼리 결과를 제공합니다.
                </Typography>
              </Box>
            </Box>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      벡터 임베딩을 활용하면 AI에 장기 기억을 제공할 수 있습니다.
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  초거대 AI 언어 모델에서 의미론적 검색을 포함하는 애플리케이션은 의미론적 정보를 나타내는 데이터 유형인 벡터 임베딩에
                  의존합니다. 이 임베딩을 통해 AI 애플리케이션은 복잡한 작업을 실행할 때 활용할 수 있는 장기 기억 저장소를 만들고 유지할 수
                  있습니다.
                </Typography>
              </Box>
            </Box>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      벡터 데이터베이스를 사용하면 임베딩을 대규모로 신속하게 저장하고 쿼리할 수 있습니다.
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  H.I.Vector는 임베딩을 위한 최적화된 저장 및 쿼리 기능을 제공합니다. 기존의 스칼라 기반 데이터베이스는 이러한 데이터의
                  복잡성과 규모를 따라잡을 수 없기 때문에 인사이트를 추출하고 실시간 분석을하기가 어렵습니다. H.I.Vector는 기존
                  데이터베이스와 유사한 친숙한 기능을 제공하고 벡터 인덱스의 신속한 성능을 함께 제공합니다.
                </Typography>
              </Box>
            </Box>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      H.I.Vector는 벡터 데이터와 원본 데이터를 함께 저장합니다.
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  H.I.Vector는 인덱스안에 각 도큐먼트들의 고유한 ID와 벡터 데이터 그리고 원본 데이터를 함께 저장할 수 있으며, Metadata를
                  이용하여 원하는 정보를 손쉽게 분류할 수 있습니다.
                </Typography>
                <Paper elevation={3} sx={{ borderRadius: "1rem", mt: 1, p: 3, backgroundColor: "rgb(202, 202, 202)" }}>
                  <Box>
                    <Typography gutterBottom className="vector-document-title">
                      H.I.Vector Document
                    </Typography>
                  </Box>
                  <Paper elevation={0} sx={{ borderRadius: "0.5rem", pt: 2, pb: 2, pl: 3, pr: 3, mt: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ width: "150px", p: 1 }}>Document ID</Box>
                      <Paper
                        elevation={0}
                        sx={{
                          borderRadius: "0.5rem",
                          backgroundColor: "rgb(202, 202, 202)",
                          p: 1,
                          width: "250px",
                          color: "rgb(92, 92, 92)",
                        }}
                      >
                        Doc-00001
                      </Paper>
                    </Box>
                  </Paper>
                  <Paper elevation={0} sx={{ borderRadius: "0.5rem", pt: 2, pb: 2, pl: 3, pr: 3, mt: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ width: "150px", p: 1 }}>Vector Value</Box>
                      <Paper
                        elevation={0}
                        sx={{
                          borderRadius: "0.5rem",
                          backgroundColor: "rgb(202, 202, 202)",
                          p: 1,
                          width: "250px",
                          color: "rgb(92, 92, 92)",
                        }}
                      >
                        [ -0.381, -0.392, -0.933, 1.707, ... ]
                      </Paper>
                    </Box>
                  </Paper>
                  <Paper elevation={0} sx={{ borderRadius: "0.5rem", pt: 2, pb: 2, pl: 3, pr: 3, mt: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ width: "150px", p: 1 }}>Text</Box>
                      <Paper
                        elevation={0}
                        sx={{
                          borderRadius: "0.5rem",
                          backgroundColor: "rgb(202, 202, 202)",
                          p: 1,
                          width: "250px",
                          color: "rgb(92, 92, 92)",
                        }}
                      >
                        동해물과 백두산이 ...
                      </Paper>
                    </Box>
                  </Paper>
                  <Paper elevation={0} sx={{ borderRadius: "0.5rem", pt: 2, pb: 2, pl: 3, pr: 3, mt: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ width: "150px", p: 1 }}>Metadata</Box>
                      <Paper
                        elevation={0}
                        sx={{
                          borderRadius: "0.5rem",
                          backgroundColor: "rgb(202, 202, 202)",
                          p: 1,
                          width: "250px",
                          color: "rgb(92, 92, 92)",
                        }}
                      >
                        {"{ 'source':'test-01', 'page':'01'}"}
                      </Paper>
                    </Box>
                  </Paper>
                </Paper>
              </Box>
            </Box>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      H.I.Vector는 별도의 설치없이 API만 호출하여 빠르게 임베딩을 Upsert 하고 쿼리합니다.
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  회원가입후 제공되는 API 주소로 HTTP 호출하면 임베딩 CRUD 및 쿼리합니다.
                </Typography>
                <Box>
                  <CodeBlock text={request_code} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
                </Box>
              </Box>
            </Box>
            <Box className="info-main-content">
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Box className="info-main-content-sub-root">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography gutterBottom className="info-main-content-sub-title">
                      가장 유사한 벡터를 리얼타임에 가까운 빠른 속도로 쿼리하세요.
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ width: "100%" }} />
                <Box>
                  <CodeBlock text={request_query} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
                </Box>
                <Typography gutterBottom className="info-main-request-body-text mt-20">
                  응답
                </Typography>
                <Box>
                  <CodeBlock text={response_query} language={"typescript"} showLineNumbers={false} startingLineNumber={1} theme={dracula} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default InfoOverview;
